.hit-name {
  font-weight: 600;
}

.ais-Hits-list {
  list-style: none;
}

.ais-Highlight-highlighted {
  background-color: rgba(128, 190, 255, 0.3);
  padding: 4px;
}

.search-form {
  width: 100%;
  height: 40px;
  margin: auto;
  position: relative;
}

.searchbox-input {
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
